.contact-container .contact-header {
    padding: 1% 30px;
}

.contact-row {
    width: 90vw;
    height: 68vh;
    display: flex;
    padding-top: 2%;
}

.contact-col-1 {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
    border-left: 2px solid #000;
}

.contact-col-2 {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;;
}

.copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
    padding-top: 20px;
    font-size: .8em;
    opacity: 0.5;
}

.form-main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;

}

.response {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 2em;
    color: #2D383A;
    text-shadow: 5px 5px 20px #2D383A;
    text-align: center;
}

.field {
    width: 80%;
    padding: 0.5rem 1rem;
    outline: none;
    border: 2px solid rgba(0, 0, 0, 0);
    background-color: rgba(230, 230, 230, 0.6);
    font-size: 1.1rem;
    margin-bottom: 22px;
    transition: .3
}

.field:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.feild:focus {
    background-color: #fff;
    border: 2px solid rgba(30, 85, 250, 0.47);
}

.area {
    min-height: 150px;
    width: 80% !important;
}

.btn {
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    background-color: #b5b5b5;
    cursor: pointer;
    outline: none;
    border: none;
    color: #fff;
}

@media (max-width: 628px) {
    .contact-row {
        flex-direction: column;
    }
    .contact-col-1 {
        border: none;
    }
    .copyright {
        opacity: 0;
    }
}