.social-nav{
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 2%;
    right: 2%;
    z-index: 10;
}

.social-nav a{
    font-size: 1.5em;
    color: #000;
}