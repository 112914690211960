.gallery {
    padding: 1% 30px;
}

.gallery-container {
    height: 100%;
    padding: 10px;
}

.cards {
    width: 350px;
    height: 350px;
    display: flex;
    /* background: #fff; */
    align-items: center;
    justify-content: center;
    /* border-top: 10px solid red; */
    padding: 20px !important;
}

.cards img {
    width: 100%;
}

.cards .bottom-text {
    position: absolute;
    bottom: 50px;
    right: 150px;
}

.alice-carousel__stage-item:not(.__active){
    visibility: hidden;

}

@media (max-width: 868px){
    .cards .bottom-text{
        right: 80px;
    }
}