.projects .pro-heade{
  padding: 1% 30px;
}

.swiper {
  width: 100%;
  height: 100%;
  padding: 10px 0 50px 0;
  user-select: none;
}

.pro-wrapper {
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 500px;
}

.cards-pro {
  height: 50%;
  width: 100%;
  display: flex;
  gap: .01em;
  align-items: center;
  flex-direction: column;
}

.cards-pro img {
  /* width: 400px; */
  display: block;
  width: 100%;
  filter: saturate(10%);

}

.swiper-slide-active .cards-pro img{
  filter: saturate(100%);
}

.button-pro {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #0f0f0f;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-pro:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-pro {
    padding: 0.25em 0.75em;
  }
}
