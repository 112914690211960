.about-container {
    padding: 1% 30px;
}

.about-main {
    height: 100%;
    width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6%;
}

.about-col-1 {
    width: 68%;
    /* height: 100%; */
    display: flex;
    padding: 0 5%;
    border-right: 3px solid #000;
    flex-direction: column;
}

.about-col-1 p {
    line-height: 2.5rem;
    text-indent: 10rem;
}

.about-col-2 {
    width: 30%;
    height: 250px;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
}

.about-col-2 h3, .about-col-1 h3 {
    text-decoration: underline;
    text-transform: uppercase;
}

.ab-cards {
    height: 100px;
    width: 300px;

}

.ab-cards h5 {
    text-decoration: double;
}

@media (max-width: 780px) {
    .about-main {
        flex-direction: column;
    }

    .about-col-1 {
        border-right: none;
        border-bottom: 3px solid #000;

    }

    .about-col-1 p, h3 {
        margin-right: 10px;
        padding: 10px;
    }
}

@media (max-width: 628px) {
    .about-col-1 {
        width: 90%;
    }

    .about-col-2 {
        width: 90%;
    }

    .about-col-1 p {
        text-indent: 0;
    }

    .about-col-2 {
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 50px;
    }

}