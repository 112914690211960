.head-main {
    padding: .5em 0;
    background: #090909;
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: space-between;
    z-index: 10;
}

.nav-img{
    padding-left: 2%;
}

.text-nav {
    padding-top: 20px;
    display: flex;
    gap: 2em;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    padding-top: 0.4em;
    flex-direction: row;
    font-size: 0.8em;
    padding-right: 5%;
}

.button-reg {
    padding: 0.6em 2em;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    text-decoration: none;
    text-transform: uppercase;
}

.button-reg:before {
    content: "";
    background: linear-gradient(45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowingbutton 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

@keyframes glowingbutton {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.button-reg:active {
    color: #000;
}

.button-reg:active:after {
    background: transparent;
}

.button-reg:hover:before {
    opacity: 1;
}

.button-reg:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0f0f0f;
    left: 0;
    top: 0;
    border-radius: 10px;
}