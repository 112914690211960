@import url('https://fonts.googleapis.com/css2?family=Kelly+Slab&display=swap');

:root{
    --main-color: #757575;
}
.land-main {
    padding: 1%;
}

.text-head {
    height: 70vh;
    width: 95vw;
    display: flex;
    margin-top: 5%;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.text-head h1 {
    text-transform: uppercase;
    font-size: 70px;
}

.text-head .quote pre{
    font-size: 2em;
}
.text-head h1 span {
    font-family: 'Kelly Slab', Helvetica, sans-serif;
    display: inline-flex;
    color: var(--main-color);
}

.text-head h1 span:nth-child(even) {
    overflow: hidden;
    transition: ease-in-out 500ms;
    color: #090909;
    border-bottom: 8px solid var(--main-color);
    letter-spacing: -1em;
    font-size: 20px !important;
}

.text-head h1:hover span:nth-child(even){
    letter-spacing: 0;
}

.text-head h1:hover span:nth-child(2){
    transition-delay:0s;
}

.text-head h1:hover span:nth-child(4){
    transition-delay: 0.5s;
}

.text-head h1:hover span:nth-child(6){
    transition-delay: 1s;
}

.text-head h1:hover span:nth-child(8){
    transition-delay: 1.5s;
}

.text-head h1:hover span:nth-child(10){
    transition-delay: 2s;
}
/* .text-head h2{
    position: relative;
    text-transform: uppercase;
    font-family: verdana;
    font-size: 9em;
    font-weight: 700;
    color: #222;
    text-shadow: 1px 1px 1px #e0e0e0,
        1px 2px 1px #e0e0e0,
        1px 3px 1px #e0e0e0,
        1px 4px 1px #e0e0e0,
        1px 5px 1px #222,
        1px 6px 1px #e0e0e0,
        1px 7px 1px #222,
        1px 8px 1px #e0e0e0,
        1px 9px 1px #222,
        1px 10px 1px #e0e0e0,
    1px 18px 6px rgba(16,16,16,0.4),
    1px 22px 10px rgba(16,16,16,0.2),
    1px 25px 35px rgba(16,16,16,0.2),
    1px 30px 60px rgba(16,16,16,0.4);
} */

@media (max-width: 1000px) {
    .text-head h1 span:nth-child(even) {
        visibility: hidden;
    }
    .text-head h1:hover span:nth-child(even){
        letter-spacing: -1em;
        transition: none;
    }
    
}