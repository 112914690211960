:root {
    --border: 10px solid #74ee15;
    --border-radius: 20px;
}

.clubs {
    padding: 1% 30px;
}
.club-container {
    margin-top: -5%;
    position: relative;
    height: 100%;
    width: 90%;
    display: flex;
    padding: 5% 2%;
    align-items: center;
    justify-content: space-around;
}

.card{
    position: relative;
    width: 300px;
    height: 200px;
    transform-style: preserve-3d;
}

.card .face{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease-in-out;
    backface-visibility: hidden;
    transform: perspective(500px) rotateY(0);
    text-align: center;
}

.card .face.front{
    flex-direction: column;
}

.card .face.front h3{
    gap: 2em;
}

.card:hover .face.front{
    transform: perspective(500px) rotateY(180deg);
}

.card .face.back{
    transform: perspective(500px) rotateY(180deg);
}

.card:hover .face.back{
    transform: perspective(500px) rotateY(360deg);
}

@media (max-width: 780px){
    .club-container {
        flex-direction: column;
        flex-wrap: wrap;
    }
}